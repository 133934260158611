import hopinApi from '@api/hopin';
import Warning from '@components/icons/warning';
import Loading from '@components/loading';
import { SelectLabel } from '@components/select/select-styles';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  useLocalization,
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { MenuSelectItem } from '@hopin-team/ui-menu';
import * as Routes from '@routes';
import { utcToZonedTime } from 'date-fns-tz';
import {
  isEmpty as _isEmpty,
  isString as _isString,
  omitBy as _omitBy,
} from 'lodash';
import { compose } from 'lodash/fp';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { timezonesMap } from '../../constants/timezones-map';
import AnnouncementsContainer from '../announcements-container';
import CancelScheduleConfirmationModal from '../announcements-list/announcements-table/cancel-schedule-confirmation-modal';
import AnnouncementsTopBar from '../announcements-top-bar';
import { AnnouncementsText, ContainerWrapper, LeftPanel } from '../styles';
import { AudienceCount } from './audience-count';
import { AudienceSelection } from './audience-selection';
import { EditScheduleEmailModal } from './edit-schedule-email-modal';
import { EmailDetailText } from './email-details-text';
import PreviewUsersSelect from './preview-users-select';
import ScheduleEmailModal from './schedule-email-modal';
import SendEmailModal from './send-email-modal';
import SendTestEmailModal from './send-test-email-modal';
import { SplitButton } from './split-button-styles';
import {
  AttendeesAlert,
  BackLink,
  CenterPanelGrid,
  EmailDetails,
  PreviewBody,
  SelectWrapper,
} from './styles';

const AnnouncementsPreview = ({
  event,
  announcementEmailTemplateId,
  initialAttendeesList,
  sendTestEmailDisabled,
}) => {
  const { t, formatDate } = useLocalization('email-dashboard');
  const [isSendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [isScheduleEmailModalOpen, setScheduleEmailModalOpen] = useState(false);
  const [isSendTestEmailModalOpen, setSendTestEmailModalOpen] = useState(false);
  const [isSendLaterModalOpen, setSendLaterModalOpen] = useState(false);
  const [isCancelScheduleModalOpen, setCancelScheduleModalOpen] = useState(
    false,
  );
  const [token, setToken] = useState(null);
  const {
    organiser_id: organiserId,
    id: eventId,
    slug: eventSlug,
    registration_count: registrationsCount,
  } = event;
  const [announcement, setAnnouncement] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { addAlert } = useContext(AlertsContext);
  const [isRecipientsPresent, setIsRecipientsPresent] = useState(false);
  const isInitialAttendeesPresent =
    initialAttendeesList && initialAttendeesList.length > 0;
  const [selectedAttendeeData, setSelectedAttendeeData] = useState(null);
  const [availableTicketTypes, setAvailableTicketTypes] = useState();
  const [scheduleOptions, setScheduledOptions] = useState();
  const { date, timezone } = scheduleOptions || {};

  const formattedDate = useMemo(() => {
    return date ? utcToZonedTime(date, timezonesMap[timezone]) : undefined;
  }, [date, timezone]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { token } = await hopinApi.getUserToken();
        setToken(token);

        const announcementData = await hopinApi.getAnnouncement(
          token,
          organiserId,
          eventId,
          announcementEmailTemplateId,
        );

        const [statusKey] = Object.keys(announcementData.status || {});

        if (statusKey === 'scheduled') {
          try {
            const {
              scheduled_time,
              timezone: templateTimezone,
            } = await hopinApi.getTemplateSchedule(
              token,
              organiserId,
              eventId,
              announcementEmailTemplateId,
            );
            setScheduledOptions({
              date: new Date(scheduled_time), // this time is already converted to UTC
              timezone: templateTimezone,
            });
          } catch (e) {
            addAlert({
              active: true,
              text: t(`list.errors.default`),
              pattern: 'error',
            });
          }
        }

        setAnnouncement(
          (({
            id,
            body,
            footer,
            name,
            subject,
            preview_text: previewText,
            audience_selection_attendee_status: attendeeStatus,
            audience_selection_ticket_types: ticketTypes,
            metadata,
            status,
          }) => ({
            id,
            body,
            footer,
            name,
            subject,
            previewText,
            attendeeStatus,
            ticketTypes,
            recipientsCount:
              metadata?.audience_selection_data?.recipients_count,
            eventAudienceCount:
              metadata?.audience_selection_data?.event_audience_count,
            status,
          }))(announcementData),
        );

        const user = await hopinApi.getUser(token);
        setUserEmail(user.email);

        const personas = await hopinApi.getTicketsWithCount(token, eventId);
        setAvailableTicketTypes(personas);

        const recipientsCount =
          announcementData.metadata.audience_selection_data.recipients_count;
        setIsRecipientsPresent(recipientsCount > 0);

        setIsLoading(false);
      } catch (e) {
        addAlert({
          active: true,
          text: t(`list.errors.default`),
          pattern: 'error',
        });
      }
    };

    fetchData();
  }, [
    addAlert,
    announcementEmailTemplateId,
    eventId,
    organiserId,
    setAnnouncement,
    setIsLoading,
    t,
    setIsRecipientsPresent,
    registrationsCount,
  ]);

  useEffect(() => {
    if (announcement?.recipientsCount) {
      setIsRecipientsPresent(!!announcement.recipientsCount);
    }
  }, [announcement, setIsRecipientsPresent]);

  const handleAttendeeSelectChange = useCallback(
    async data => {
      setSelectedAttendeeData(data);
      const newRegistrationId = data.value;

      try {
        const {
          subject,
          body,
          footer,
          preview_text: previewText,
        } = await hopinApi.previewAnnouncement(
          token,
          organiserId,
          eventId,
          announcement.id,
          newRegistrationId,
        );

        const previewData = _omitBy(
          { subject, body, footer, previewText },
          attr => !_isString(attr) || _isEmpty(attr),
        );

        setAnnouncement({
          ...announcement,
          ...previewData,
        });
      } catch (e) {
        addAlert({
          active: true,
          text: t(`list.errors.default`),
          pattern: 'error',
        });
      }
    },
    [eventId, setAnnouncement, organiserId, announcement, token, addAlert, t],
  );

  const selectOptions = initialAttendeesList.map(
    ({ name: label, id: value, email: email }) => ({ label, value, email }),
  );

  const handleCancelSchedule = async () => {
    const [statusKey] = Object.keys(announcement.status);
    if (statusKey === 'scheduled') {
      setCancelScheduleModalOpen(true);
    } else {
      setScheduledOptions({});
    }
  };

  const confirmCancelSchedule = async () => {
    try {
      await hopinApi.cancelScheduledEmail(
        token,
        organiserId,
        eventId,
        announcementEmailTemplateId,
      );
      const { status } = await hopinApi.getAnnouncement(
        token,
        organiserId,
        eventId,
        announcementEmailTemplateId,
      );
      setCancelScheduleModalOpen(false);
      addAlert({
        active: true,
        text: t(`cancel-schedule.success`),
        pattern: 'warning',
      });
      setAnnouncement({
        ...announcement,
        status,
      });
      setScheduledOptions({});
    } catch (e) {
      addAlert({
        active: true,
        text: t(`list.errors.default`),
        pattern: 'error',
      });
    }
  };

  return (
    <ContainerWrapper data-testid="announcements-preview">
      <AnnouncementsTopBar
        event={event}
        pageBreadcrumb={t(`preview`)}
        rightActions={() => (
          <>
            <Button
              data-testid="send-test-email-button"
              isInline
              pattern="inverse"
              isOutlined
              size="small"
              mr={2}
              disabled={sendTestEmailDisabled}
              onClick={() => setSendTestEmailModalOpen(true)}
            >
              {sendTestEmailDisabled
                ? t(`send-test-email.disabled-maintenance`)
                : t(`send-test-email.title`)}
            </Button>
            {isRecipientsPresent || date ? (
              <SplitButton
                onClick={() => {
                  formattedDate
                    ? setScheduleEmailModalOpen(true)
                    : setSendEmailModalOpen(true);
                }}
                data-testid="send-email-button"
                pattern="primary"
                size="tiny"
                menuItems={[
                  ...(formattedDate
                    ? [
                        <MenuSelectItem
                          key="cancel-schedule-menu-item"
                          p={1}
                          label={t(`schedule-email.cancel-scheduling`)}
                          onClick={handleCancelSchedule}
                        ></MenuSelectItem>,
                        <MenuSelectItem
                          key="edit-schedule-menu-item"
                          p={1}
                          label={t(`schedule-email.change-date`)}
                          onClick={() => setSendLaterModalOpen(true)}
                        ></MenuSelectItem>,
                      ]
                    : [
                        <MenuSelectItem
                          key="schedule-menu-item"
                          p={1}
                          label={t(`schedule-email.send-later`)}
                          onClick={() => setSendLaterModalOpen(true)}
                        ></MenuSelectItem>,
                      ]),
                ]}
              >
                {formattedDate
                  ? `${t('schedule-email.title')} (${formatDate(
                      formattedDate,
                      'MMM d - hh:mm aa',
                    )})`
                  : t(`send-email.title`)}
              </SplitButton>
            ) : (
              <Button
                isInline
                size="small"
                mr={2}
                onClick={() => {
                  formattedDate
                    ? setScheduleEmailModalOpen(true)
                    : setSendLaterModalOpen(true);
                }}
                data-testid="schedule-email-button"
              >
                {formattedDate
                  ? `${t('schedule-email.title')} (${formatDate(
                      formattedDate,
                      'MMM d - hh:mm aa',
                    )})`
                  : t(`schedule-email.send-later`)}
              </Button>
            )}
          </>
        )}
      />
      <Alerts />
      <Loading isLoading={isLoading}>
        {!isLoading && (
          <AnnouncementsContainer>
            <LeftPanel>
              <BackLink
                href={Routes.editOrganisersEventAnnouncementPath(
                  eventSlug,
                  announcementEmailTemplateId,
                )}
                data-testid="back-to-edit-link"
                aria-labelledby="Back"
                name="arrow-back"
                color="grey-600"
                scale={2}
              />
            </LeftPanel>
            <CenterPanelGrid>
              <AnnouncementsText>
                {t(`previewAndReviewPage.backToEdit`)}
              </AnnouncementsText>
              <EmailDetails>
                <AudienceSelection
                  status={announcement.attendeeStatus}
                  ticketTypes={announcement.ticketTypes}
                  hasTicketTypes={!!availableTicketTypes.length}
                ></AudienceSelection>
                <EmailDetailText
                  label={t(`previewAndReviewPage.emailSubject`)}
                  value={announcement.subject}
                  data-testid="email-subject"
                />
                <EmailDetailText
                  label={t(`template.preview-text`)}
                  value={
                    announcement.previewText ||
                    t(`previewAndReviewPage.no-preview-text-set`)
                  }
                  data-testid="email-preheader"
                />
              </EmailDetails>
              <AudienceCount
                recipientsCount={announcement.recipientsCount}
                eventAudienceCount={announcement.eventAudienceCount}
              />
              {!isInitialAttendeesPresent && (
                <AttendeesAlert p={2} data-testid="no-attendees-alert">
                  <Warning />
                  <AnnouncementsText>
                    {t(`previewAndReviewPage.noTicketsError`)}
                  </AnnouncementsText>
                </AttendeesAlert>
              )}
              <SelectWrapper>
                <SelectLabel htmlFor="attendee-for-preview">
                  {t(`previewAndReviewPage.previewEmailTo`)}
                </SelectLabel>
                <PreviewUsersSelect
                  eventId={eventId}
                  registrationsCount={registrationsCount}
                  onChange={handleAttendeeSelectChange}
                  id="attendee-for-preview"
                  options={selectOptions}
                  name="registration_id"
                />
              </SelectWrapper>
              <PreviewBody
                data-testid="email-body"
                dangerouslySetInnerHTML={{
                  __html: announcement.body + announcement.footer,
                }}
              />
            </CenterPanelGrid>
            <SendEmailModal
              event={event}
              isModalDisplayed={isSendEmailModalOpen}
              handleClose={() => setSendEmailModalOpen(false)}
              announcementEmailTemplateId={announcementEmailTemplateId}
              recipientsCount={announcement.recipientsCount}
              eventAudienceCount={announcement.eventAudienceCount}
              token={token}
              status={announcement.attendeeStatus}
              selectedTicketTypes={announcement.ticketTypes}
              ticketTypes={availableTicketTypes}
              subject={announcement.subject}
            />
            <>
              <EditScheduleEmailModal
                event={event}
                isModalDisplayed={isSendLaterModalOpen}
                date={date}
                timezone={timezone}
                canSend={isRecipientsPresent}
                onSendNow={() => {
                  setSendLaterModalOpen(false);
                  setSendEmailModalOpen(true);
                }}
                onSubmit={(date, timezone) => {
                  setSendLaterModalOpen(false);
                  setScheduledOptions({ date, timezone });
                }}
                onCancel={() => {
                  setSendLaterModalOpen(false);
                }}
              />
              <ScheduleEmailModal
                event={event}
                isModalDisplayed={isScheduleEmailModalOpen}
                handleClose={() => setScheduleEmailModalOpen(false)}
                announcementEmailTemplateId={announcementEmailTemplateId}
                announcementStatus={Object.keys(announcement.status)[0]}
                recipientsCount={announcement.recipientsCount}
                eventAudienceCount={announcement.eventAudienceCount}
                token={token}
                status={announcement.attendeeStatus}
                selectedTicketTypes={announcement.ticketTypes}
                ticketTypes={availableTicketTypes}
                subject={announcement.subject}
                scheduleOptions={scheduleOptions}
              />
              <CancelScheduleConfirmationModal
                isModalDisplayed={isCancelScheduleModalOpen}
                handleClose={() => setCancelScheduleModalOpen(false)}
                confirmCancel={confirmCancelSchedule}
                data-testid="cancel-schedule-confirm-modal"
              />
            </>
          </AnnouncementsContainer>
        )}
      </Loading>
      <SendTestEmailModal
        event={event}
        isModalDisplayed={isSendTestEmailModalOpen}
        handleClose={() => setSendTestEmailModalOpen(false)}
        announcementEmailTemplateId={announcementEmailTemplateId}
        selectedAttendeeData={selectedAttendeeData}
        defaultEmail={userEmail}
        token={token}
        selectOptions={selectOptions}
      />
    </ContainerWrapper>
  );
};

AnnouncementsPreview.propTypes = {
  event: shape({
    name: string.isRequired,
    picture: string.isRequired,
    slug: string.isRequired,
    registration_count: number.isRequired,
  }).isRequired,
  announcementEmailTemplateId: string.isRequired,
  initialAttendeesList: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      email: string.isRequired,
    }).isRequired,
  ),
  sendTestEmailDisabled: bool,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
  withLocalization,
  withThemeProvider,
)(AnnouncementsPreview);
