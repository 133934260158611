import api from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { AuthenticationContext } from '@hopin-team/provider-authentication';
import { Avatar } from '@hopin-team/ui-avatar';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { SidePanelModal } from '@hopin-team/ui-side-panel-modal';
import { Spinner } from '@hopin-team/ui-spinner';
import { Tab, TabList, TabPanel, TabPanels } from '@hopin-team/ui-tabs';
import { Text } from '@hopin-team/ui-text';
import { array, bool, func, number, shape, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  mapToAnswerFormData,
  mapToAnswers,
  useAttendeeCustomAnswerForm,
  useAttendeeRegistrationForm,
} from './attendee-details-forms';
import { AttendeeFormCloseModal } from './attendee-details-modal';
import {
  Body,
  Container,
  CustomAnswerForm,
  Footer,
  Header,
  RegistrationForm,
  TabHeader,
  Tabs,
  TabTitle,
} from './attendee-details-tabbed.styles';
import { UpdateTicketModal } from './update-ticket-modal';
import { useRegistrationDetails } from './use-registration-details';

export const AttendeeDetailsTabs = ({
  attendee: initialAttendee,
  eventSlug,
  onClose,
  onRefundTicket,
  ticketTypes,
  registrantEditableTicketTypeEnabled,
}) => {
  const { t } = useLocalization('people.attendees.attendees-tab.details');
  const { addAlert } = useContext(AlertsContext);
  const [isEditing, setIsEditing] = useState(false);
  const [{ showModal, oldTicket, newTicket, code }, setTicketState] = useState({
    showModal: false,
  });
  const [formData, setFormData] = useState({});

  const toggleEditing = useCallback(
    () => setIsEditing(editing => !editing),
    [],
  );
  const [discardModalSource, setDiscardModalSource] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const isShowingQuestions = activeTab === 1;
  const isEditable = isShowingQuestions || registrantEditableTicketTypeEnabled;

  useEffect(() => {
    setIsEditing(editing => {
      if (editing) {
        return isEditable;
      }
    });
  }, [isEditable]);

  const shouldShowDiscardModal = () => {
    const selectedTicketType = availableTickets?.find(
      option => option.name === attendee?.ticket_type.label,
    );

    const formValues = registrationForm.getValues();

    return selectedTicketType && formValues.ticket !== selectedTicketType?.id;
  };

  const handleDiscardButtonClick = () => {
    if (shouldShowDiscardModal()) {
      setDiscardModalSource('BUTTON');
      return;
    }

    toggleEditing();
  };

  const handleSidepanelClose = () => {
    if (shouldShowDiscardModal()) {
      setDiscardModalSource('SIDEPANEL');
      return;
    }

    onClose();
  };

  const handleModalDiscardButtonClick = () => {
    registrationForm.reset();
    if (discardModalSource === 'SIDEPANEL') {
      onClose();
    }
    setDiscardModalSource(false);
    toggleEditing();
  };

  const handleModalKeepButtonClick = () => {
    setDiscardModalSource(false);
  };

  const {
    attendee,
    customForm,
    error,
    invalidate,
    isLoading,
    availableTickets,
  } = useRegistrationDetails(eventSlug, initialAttendee.id);

  const { authenticationToken } = useContext(AuthenticationContext);
  const registrationForm = useAttendeeRegistrationForm(
    attendee,
    availableTickets,
  );
  const customAnswerForm = useAttendeeCustomAnswerForm(
    attendee?.answers,
    customForm?.questions,
  );

  const submitForm = useCallback(
    async formData => {
      const updatedAnswers = mapToAnswers(customForm?.questions, formData);

      if (updatedAnswers.length === 0) {
        setIsEditing(false);
        return;
      }

      await api.updateRegistrationCustomAnswers(
        eventSlug,
        initialAttendee.id,
        updatedAnswers,
        authenticationToken,
      );

      invalidate();
      customAnswerForm.reset(
        mapToAnswerFormData(customForm?.questions, updatedAnswers),
      );
    },
    [
      customForm?.questions,
      attendee?.answers,
      eventSlug,
      initialAttendee.id,
      invalidate,
      customAnswerForm,
      authenticationToken,
    ],
  );

  const submitAllForms = useCallback(
    async (formData, newTicket) => {
      try {
        await Promise.all([
          api.eventRegistrantUpdateTicket({
            eventRegistrantId: attendee.external_id,
            ticketId: newTicket.id,
            confirm: true,
          }),
          submitForm(formData),
        ]);
        addAlert({
          active: true,
          text: t('success', {
            name: initialAttendee.name,
            ticket: newTicket.name,
          }),
          pattern: 'success',
        });
        onClose(true);
      } catch (e) {
        addAlert({
          active: true,
          text: t('error.generic'),
          pattern: 'error',
        });
      }
    },
    [submitForm, attendee?.external_id],
  );

  const onSubmitCustomAnswerForm = useCallback(
    async formData => {
      const newTicketId = registrationForm.getValues().ticket;
      const oldTicket = availableTickets.find(
        option => option.name === attendee?.ticket_type.label,
      );

      if (newTicketId !== oldTicket.external_id) {
        setFormData(formData);

        const newTicket = availableTickets.find(({ id }) => id === newTicketId);

        const {
          data: {
            eventRegistrantUpdateTicket: { userErrors },
          },
        } = await api.eventRegistrantUpdateTicket({
          eventRegistrantId: attendee.external_id,
          ticketId: newTicketId,
          confirm: false,
        });

        if (userErrors.length) {
          const [{ code }] = userErrors;
          setTicketState({
            showModal: true,
            oldTicket,
            newTicket,
            code,
          });
        } else {
          submitAllForms(formData, newTicket);
          onClose(true);
        }
      } else {
        await submitForm(formData);
      }
    },
    [attendee, availableTickets, registrationForm, submitAllForms, submitForm],
  );

  const isSaving =
    registrationForm.formState.isSubmitting ||
    customAnswerForm.formState.isSubmitting;
  const onClickSave = useCallback(() => {
    customAnswerForm.handleSubmit(onSubmitCustomAnswerForm)();
  }, [customAnswerForm, onSubmitCustomAnswerForm]);

  const closeUpdateTicket = () => {
    setTicketState({ showModal: false });
  };

  const confirmUpdateTicket = async () => {
    setTicketState(state => ({ ...state, showModal: false }));
    submitAllForms(formData, newTicket);
  };

  return (
    <>
      <SidePanelModal
        isShowing={!showModal && discardModalSource === false}
        onClose={handleSidepanelClose}
        isDismissible={!showModal && discardModalSource === false}
        closeLabel={t('actions.close')}
      >
        <Container data-testid="reg-attendee-details-tabbed-form">
          <Header p={3}>
            <Text pattern="headingThree">{t('header.title')}</Text>
          </Header>
          <Body px={3} py={1}>
            <Flex alignItems="center" mb={3}>
              <Avatar
                firstName={initialAttendee.first_name}
                lastName={initialAttendee.last_name}
                size="gigantic"
                mr={3}
              />
              <Box>
                <Text pattern="subHeadingBold">{initialAttendee.name}</Text>
                <Text as="p" pattern="caption">
                  {initialAttendee.email}
                </Text>
              </Box>
            </Flex>

            <Tabs onChange={setActiveTab}>
              <TabHeader>
                <TabList>
                  <Tab>
                    <TabTitle>{t('tabs.registration')}</TabTitle>
                  </Tab>
                  <Tab>
                    <TabTitle>{t('tabs.answers')}</TabTitle>
                  </Tab>
                </TabList>
              </TabHeader>

              {isLoading ? (
                <Flex justifyContent="center" alignItems="center">
                  <Spinner isPolite isShowing pattern="jumbo" />
                </Flex>
              ) : error ? (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text element="h3" pattern="subHeadingBold" p={3}>
                    {t('error.generic')}
                  </Text>
                  <Text p={3}>{t('error.retry')}</Text>
                </Flex>
              ) : (
                <TabPanels>
                  <TabPanel>
                    <RegistrationForm
                      form={registrationForm}
                      ticketTypes={ticketTypes}
                      availableTickets={availableTickets}
                      editable={isEditing}
                    />
                  </TabPanel>
                  <TabPanel>
                    <CustomAnswerForm
                      answers={attendee?.answers}
                      editable={isEditing}
                      form={customAnswerForm}
                      questions={customForm?.questions}
                      onSubmit={onSubmitCustomAnswerForm}
                    />
                  </TabPanel>
                </TabPanels>
              )}
            </Tabs>
          </Body>
          <Footer justifyContent="flex-start" p={3}>
            {isEditing ? (
              <>
                <Button
                  size="medium"
                  isInline
                  pattern="secondary"
                  onClick={handleDiscardButtonClick}
                >
                  {t('actions.discard.label')}
                </Button>
                <Button
                  disabled={isSaving}
                  onClick={onClickSave}
                  size="medium"
                  isInline
                >
                  {t('actions.save.label')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={isLoading}
                  size="medium"
                  isInline
                  leadingIcon="ticket"
                  pattern="secondary"
                  onClick={onRefundTicket}
                >
                  {t('actions.refund.label')}
                </Button>
                <Button
                  disabled={!isEditable}
                  size="medium"
                  isInline
                  onClick={toggleEditing}
                >
                  {t('actions.edit.label')}
                </Button>
              </>
            )}
          </Footer>
        </Container>
      </SidePanelModal>
      <UpdateTicketModal
        isShowing={showModal}
        name={initialAttendee.name}
        oldTicket={oldTicket}
        newTicket={newTicket}
        code={code}
        onCancel={closeUpdateTicket}
        onConfirm={confirmUpdateTicket}
      />
      <AttendeeFormCloseModal
        isShowing={discardModalSource !== false}
        onDiscardChangesClick={handleModalDiscardButtonClick}
        onKeepEditingClick={handleModalKeepButtonClick}
      />
    </>
  );
};

AttendeeDetailsTabs.propTypes = {
  attendee: shape({
    id: number,
    name: string,
    full_name: string,
    last_name: string,
  }).isRequired,
  eventSlug: string.isRequired,
  onClose: func.isRequired,
  onRefundTicket: func.isRequired,
  ticketTypes: array.isRequired,
  registrantEditableTicketTypeEnabled: bool.isRequired,
};
