import styled from 'styled-components';

export const DEFAULT_COLORS = [
  '#FF7A1A',
  '#E64646',
  '#36BA79',
  '#696E7C',
  '#2C3242',
];

export const BRANDING_COLORS = [
  'var(--color-primary-500)',
  'var(--color-primary-400)',
  'var(--color-primary-300)',
  'var(--color-primary-200)',
  'var(--color-primary-100)',
];

export const ClassicEditorResizableContainer = styled.div`
  height: ${props => (props.height ? `${props.height}rem` : '17rem')};
  resize: vertical;
  border-bottom-left-radius: var(--ck-border-radius);
  border-bottom-right-radius: var(--ck-border-radius);

  .ck.ck-editor {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ck-editor__main {
    flex: 1;
    overflow: auto;
  }

  .ck.ck-editor__top.ck-reset_all {
    z-index: var(--ck-z-modal);
    top: 0;
    background-color: var(--color-gray-100);
  }

  .ck.ck-sticky-panel__placeholder {
    display: none !important;
  }

  .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
    position: unset;
    width: auto !important;
    top: 0 !important;
    margin-left: 0 !important;
  }

  .ck.ck-content.ck-editor__editable {
    height: 100%;
  }

  .ck.ck-editor__editable .ck-widget .ck-widget__type-around__button {
    display: none;
  }

  .ck.ck-widget__selection-handle {
    display: none;
  }

  .ck-widget {
    outline: unset;
    background: unset;
    animation: none;
    transition: none !important;
  }

  .ck-editor__editable
    .ck-editor__nested-editable
    .ck-editor__nested-editable_focused,
  .ck-editor__editable .ck-editor__nested-editable:focus {
    outline: unset !important;
    background: unset;
  }

  .ck .ck-widget.ck-widget_selected {
    outline: unset;
    background: unset;
  }

  .ck-editor__editable
    .ck-editor__nested-editable
    .ck-editor__nested-editable_focused:focus {
    outline: unset;
    background: unset;
  }

  .ck-editor__nested-editable:focus {
    outline: unset;
    background: unset;
  }

  .ck .ck-widget.ck-widget_selected:hover {
    outline: unset;
    background: unset;
  }

  .ck .ck-widget:hover {
    outline: unset;
    background: unset;
  }

  .widget-liquid-tag {
    outline: 2px solid var(--color-primary-400) !important;
  }

  .widget-liquid-tag:hover {
    background-color: var(--color-primary-200);
    outline: 2px solid var(--color-primary-500) !important;
  }

  .ck.ck-dropdown .ck-button.ck-dropdown__button .ck-button__label {
    width: 12em;
  }

  .ck.ck-dropdown__panel {
    max-height: 300px;
    overflow-y: auto;
  }

  .liquid-tag__button span.ck.ck-button__label {
    opacity: unset;
    color: var(--color-text-dark);
  }

  .liquid-tag__group-label span.ck.ck-button__label {
    font-weight: var(--font-weight-bold);
    color: var(--color-text-dark);
    opacity: unset;
    font-size: 13px;
  }
`;
