import {
  getDefaultEndDate,
  getDefaultStartDate,
} from '@features/event-creation-wizard/utils/dates';
import { createSlice } from '@reduxjs/toolkit';

const defaultEventLength = value => {
  if (value > 72) return 72;
  if (value <= 8) return 1;
  return value;
};

export const getInitialBasicDetailsState = (
  defaultMaxEventLengthHours,
  defaultEventImageUrl,
) => {
  const FREE_PLAN_MAX_EVENT_HOURS = 2;
  const maxEventLengthHours = Number.isFinite(defaultMaxEventLengthHours)
    ? defaultMaxEventLengthHours
    : FREE_PLAN_MAX_EVENT_HOURS;

  return {
    name: '',
    timezone: '',
    imageUrl: '',
    maxEventLengthHours,
    startDate: getDefaultStartDate(),
    endDate: getDefaultEndDate(
      defaultEventLength(maxEventLengthHours) * 60 ** 2,
    ),
    eventPassword: '',
    defaultEventImageUrl,
    defaultMaxEventLengthHours,
  };
};

const basicDetails = createSlice({
  name: 'event-creation-wizard-basic-details',
  initialState: getInitialBasicDetailsState(),
  reducers: {
    setBasicDetails: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setMaxEventLengthHours: (state, action) => {
      const { maxEventLengthHours } = action.payload;

      return {
        ...state,
        maxEventLengthHours: maxEventLengthHours,
      };
    },
    resetEndDate: state => {
      return {
        ...state,
        endDate: getDefaultEndDate(
          defaultEventLength(state.maxEventLengthHours) * 60 ** 2,
        ),
      };
    },
  },
});

const {
  reducer,
  actions: {
    setBasicDetails,
    setMaxEventLengthHours,
    resetEndDate,
    setEndDate,
  },
} = basicDetails;

export { setBasicDetails, setMaxEventLengthHours, resetEndDate, setEndDate };

export default reducer;
