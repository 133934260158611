import '@hopin-team/ckeditor5-build-hopin/dist/styles.css';
import './languages';
import './styles.css';

import CKEditor from '@ckeditor/ckeditor5-react';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import Editor from '@hopin-team/ckeditor5-build-hopin';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

Editor.IFRAMELY_API_KEY_HASH = process.env.IFRAMELY_API_KEY_HASH;

const REACT_INTEGRATION_READ_ONLY_LOCK_ID = 'LOCK';

const BRANDING_COLORS = [
  'var(--color-primary-500)',
  'var(--color-primary-400)',
  'var(--color-primary-300)',
  'var(--color-primary-200)',
  'var(--color-primary-100)',
];

const ResizableContainer = styled.div`
  height: ${props => (props.height ? `${props.height}rem` : '17rem')};
  overflow: auto;
  resize: vertical;
  border-bottom-left-radius: var(--ck-border-radius);
  border-bottom-right-radius: var(--ck-border-radius);

  .ck.ck-content.ck-editor__editable {
    height: 100%;
  }
`;

const DEFAULT_COLORS = ['#FF7A1A', '#E64646', '#36BA79', '#696E7C', '#2C3242'];

const CKEditor5 = forwardRef(
  (
    {
      name,
      initialValue,
      uploadS3PolicyUrl,
      maxFileSize,
      onChange,
      toolbar,
      disabled = false,
      height,
    },
    ref,
  ) => {
    const [data, setData] = useState(initialValue);
    const toolbarRef = useRef();
    const editorRef = useRef();
    const { language } = useLocalization();

    useEffect(() => {
      if (!editorRef.current) return;

      if (disabled) {
        editorRef.current.enableReadOnlyMode(
          REACT_INTEGRATION_READ_ONLY_LOCK_ID,
        );
      } else {
        editorRef.current.disableReadOnlyMode(
          REACT_INTEGRATION_READ_ONLY_LOCK_ID,
        );
      }
    }, [disabled]);

    const config = {
      fontColor: {
        columns: 5,
        colors: [...BRANDING_COLORS, ...DEFAULT_COLORS].map(color => ({
          color,
        })),
      },
      language,
    };

    if (uploadS3PolicyUrl && maxFileSize) {
      config.s3Upload = {
        policyUrl: uploadS3PolicyUrl,
        maxFileSize: maxFileSize,
      };
    }

    if (toolbar === 'min') {
      config.toolbar = {
        items: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          // TODO: ST-1383 will fix and re-enable
          // 'link',
          'removeFormat',
          '|',
          'bulletedList',
          'numberedList',
        ],
      };
    }

    return (
      <div>
        <textarea
          value={data}
          name={name}
          style={{ display: 'none' }}
          readOnly
        ></textarea>

        <div className="document-editor">
          <div className="document-editor__toolbar" ref={toolbarRef}></div>
          <div className="document-editor__editable-container">
            <ResizableContainer height={height}>
              <CKEditor
                config={config}
                onInit={editor => {
                  editorRef.current = editor;

                  toolbarRef.current.appendChild(
                    editor.ui.view.toolbar.element,
                  );

                  if (disabled) {
                    editor.enableReadOnlyMode(
                      REACT_INTEGRATION_READ_ONLY_LOCK_ID,
                    );
                  }
                }}
                ref={ref}
                editor={Editor}
                data={initialValue}
                onChange={(event, editor) => {
                  setData(editor.getData());
                  if (onChange) onChange(event, editor);
                }}
              />
            </ResizableContainer>
          </div>
        </div>
      </div>
    );
  },
);

CKEditor5.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  uploadS3PolicyUrl: PropTypes.string,
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  toolbar: PropTypes.oneOf(['min']),
  disabled: PropTypes.bool,
  height: PropTypes.number,
};

export default withLocalizationProvider(CKEditor5);
