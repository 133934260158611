import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { getBrowserTimezone } from '@util/date-helpers';
import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import { array, string } from 'prop-types';
import React from 'react';

import ScheduleSpeaker from './schedule-speakers-component';
import { Styles } from './schedule-styles';

const CameraIcon = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0003 0.666656C10.7367 0.666656 11.3337 1.26361 11.3337 1.99999V3.87332L14.2662 1.4697C14.5604 1.24835 14.9784 1.30743 15.1997 1.60166C15.2867 1.7172 15.3337 1.85786 15.3337 2.00244V10.0024C15.3337 10.3706 15.0352 10.6691 14.667 10.6691C14.5231 10.6691 14.383 10.6225 14.2678 10.5364L11.3337 8.14066V9.99999C11.3337 10.7364 10.7367 11.3333 10.0003 11.3333H2.00033C1.26395 11.3333 0.666992 10.7364 0.666992 9.99999V1.99999C0.666992 1.26361 1.26395 0.666656 2.00033 0.666656H10.0003ZM3.66699 2.66666C3.11471 2.66666 2.66699 3.11437 2.66699 3.66666C2.66699 4.21894 3.11471 4.66666 3.66699 4.66666C4.21928 4.66666 4.66699 4.21894 4.66699 3.66666C4.66699 3.11437 4.21928 2.66666 3.66699 2.66666Z"
      fill="#696E7C"
    />
  </svg>
);

export const formatLocalDatetimeString = (
  utcDatetimeString,
  pattern = 'MMM d, h:mma z',
) => {
  return format(
    utcToZonedTime(toDate(utcDatetimeString), getBrowserTimezone()),
    pattern,
    {
      timeZone: getBrowserTimezone(),
    },
  );
};

const ScheduleList = ({ list, eventRoute }) => {
  return (
    <>
      {list.map((scheduleItem, idx) => {
        const hasSpeakers = Boolean(scheduleItem.speaker_profiles?.length);

        return (
          <Box key={idx} cornerRadius="lg" border="grey-200" p={[2, 3]} mb={3}>
            <Styles.ScheduleGrid>
              <div>
                <Styles.Stage pattern="body" element="p" scale={2} mb={1}>
                  <CameraIcon />
                  {scheduleItem.event_part_type}
                </Styles.Stage>
                <Text pattern="labelOne" element="p" mb={2}>
                  {formatLocalDatetimeString(scheduleItem.time_start, 'h:mma')}{' '}
                  - {formatLocalDatetimeString(scheduleItem.time_end, 'h:mma')}
                </Text>

                <div
                  title="Add to Calendar"
                  className="addeventatc"
                  data-styling="none"
                  data-event_label={`event.name-schedule.name`}
                >
                  <Styles.CalendarButton
                    isInline
                    isOutlined
                    leadingIcon="calendar-note"
                    size="small"
                  >
                    Add to calendar
                  </Styles.CalendarButton>
                  <span className="start">{scheduleItem.starts_at}</span>
                  <span className="end">{scheduleItem.ends_at}</span>
                  <span className="timezone">{scheduleItem.timezone}</span>
                  <span className="title">{scheduleItem.name}</span>
                  <span className="description">{eventRoute}</span>
                  <span className="location">
                    Hosted virtually on RingCentral Events (link in the
                    description)
                  </span>
                </div>
              </div>
              <div>
                <Text pattern="headingFour" element="h4" mb={1}>
                  {scheduleItem.name}
                </Text>
                <Text pattern="body" element="p" mb={3}>
                  {scheduleItem.description}
                </Text>
                {hasSpeakers && (
                  <ScheduleSpeaker
                    key={idx}
                    speakers={scheduleItem.speaker_profiles}
                  />
                )}
              </div>
            </Styles.ScheduleGrid>
          </Box>
        );
      })}
    </>
  );
};

ScheduleList.propTypes = {
  list: array,
  eventRoute: string,
};

export default ScheduleList;
