import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { calcSpace, COLOR, LINE } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import styled, { css } from 'styled-components';

export const Styles = {};

Styles.ItemMargin = styled.div`
 ${({ isDeepLevel }) =>
   !isDeepLevel &&
   css`
     margin-left: 0.5rem;
     margin-right: 0.5rem;
   `}

  margin-top: 1rem;

  &:first-child {
    margin-top: 0.5rem;
`;

Styles.Arrow = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  width: ${calcSpace(2.5)};
  height: ${calcSpace(2.5)};
  border-radius: ${LINE.RADIUS_SM};
  display: flex;
  color: ${COLOR.GREY.WHITE};

  ${Icon} {
    transition: transform 200ms ease-out;
  }
`;

Styles.ItemIcon = styled(Icon)`
  ${({ isDeepLevel }) => css`
    visibility: ${isDeepLevel ? 'hidden' : 'visible'};
  `}
`;

Styles.Crown = styled.div`
  margin-left: 0.5rem;
`;

const rootItemStyle = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-top: ${({ borderTop }) =>
    borderTop ? css`${LINE.WIDTH} solid ${COLOR.GREY['300']}` : ''};
  text-decoration: none;
  border-radius: 0.5rem;

  ${({ isActive, isExpanded }) => css`
    background-color: ${isActive && !isExpanded
      ? 'var(--color-nav-hover)'
      : 'var(--color-nav-background)'};
    color: ${isActive && !isExpanded ? COLOR.BLUE['500'] : COLOR.GREY['800']};
  `}

  &:hover {
    color: ${COLOR.GREY[900]};
    background-color: var(--color-nav-hover);
  }

  &:focus:not([data-focus-visible-added]) {
    outline: 0;
  }
`;

Styles.RootItem = styled.button`
  ${rootItemStyle};
`;

Styles.TopLevelItem = styled.button`
  ${rootItemStyle};
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-top: ${({ borderTop }) =>
    borderTop ? css`${LINE.WIDTH} solid ${COLOR.GREY['300']}` : ''};

  &:hover {
    ${Styles.ItemIcon} {
      visibility: ${({ hasChildren }) => (hasChildren ? 'hidden' : null)};
    }

    ${Styles.Arrow} {
      visibility: visible;
      background-color: var(--color-nav-hover);
    }
  }

  ${Text} {
    font-weight: ${({ isActive, isExpanded, isDeepLevel }) =>
      isActive || (isDeepLevel && isExpanded) ? 700 : 400};
  }

  ${Styles.Arrow} {
    ${({ isExpanded }) =>
      isExpanded
        ? css`
            ${Icon} {
              background-color: transparent;
              transform: rotate(90deg);
            }
          `
        : ''}

    ${({ isExpanded, isDeepLevel }) =>
      isExpanded || isDeepLevel
        ? css`
            visibility: visible;
          `
        : css`
            visibility: hidden;
          `};
  }

  ${Styles.ItemIcon} {
    color: inherit;
    visibility: ${({ isExpanded }) => (isExpanded ? 'hidden' : 'visisble')};
  }

  ${Styles.Arrow} {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
  }
`;

Styles.SubItem = styled('button').withConfig({
  shouldForwardProp: prop =>
    ['data-menu-action', 'href', 'to', 'children', 'tabIndex'].includes(prop),
})`
  ${rootItemStyle};
  padding-left: 3rem;
  margin-top: 1rem;

  ${Text} {
    font-weight: 400;
  }

  &:hover {
    background-color: var(--color-nav-hover);
  }
`;

Styles.Notification = styled.div`
  line-height: 0;
  padding-left: 0.5rem;
`;
