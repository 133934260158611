import { Input } from '@components/inputs';
import EventCustomApp from '@features/events/event-custom-app';
import EventCustomArea from '@features/events/event-custom-area';
import eventPartsInfo from '@features/events/event-parts/event-parts-info.json';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Grid } from '@hopin-team/ui-grid';
import { SelectGroup } from '@hopin-team/ui-select-items';
import { Text } from '@hopin-team/ui-text';
import { isEmpty } from 'lodash';
import { arrayOf, bool, number, object, shape, string } from 'prop-types';
import React, { useState } from 'react';

import EventPart from './event-parts/event-part-component';

const CUSTOM_AREA = 'custom-area';
const CUSTOM_APP = 'custom-app';

const initialEventPartsState = (
  eventParts,
  customAreaEnabled,
  customAreaEvent,
  customAreaIconUrl,
  customAppEnabled,
  customApps,
  customAppEvent,
  customAppIconUrl,
) => {
  let eventPartsState = eventParts.reduce(
    (acc, part) => ({
      ...acc,
      [part.event_part_type]: part.visible,
    }),
    {},
  );

  eventPartsState['custom-area'] =
    customAreaEnabled &&
    !(
      isEmpty(customAreaEvent?.title) &&
      isEmpty(customAreaEvent?.url) &&
      isEmpty(customAreaIconUrl)
    );

  eventPartsState['custom-app'] =
    customAppEnabled &&
    !(
      isEmpty(customAppEvent?.title) &&
      isEmpty(customAppEvent?.url) &&
      isEmpty(customAppIconUrl)
    );

  return eventPartsState;
};

const defaultLandingArea = eventPartChecked => {
  return Array.from(
    Object.entries(eventPartChecked)
      .filter(([, checked]) => checked)
      .map(([type]) => type),
  ).shift();
};

const EventParts = ({
  legend,
  eventParts,
  customAreaEnabled,
  customAreaEvent,
  customAreaIconUrl,
  customAppEnabled,
  customApps,
  customAppEvent,
  customAppIconUrl,
  eventLandingArea,
  isLite,
}) => {
  const { t } = useLocalization();

  const [eventPartChecked, setEventPartChecked] = useState(
    initialEventPartsState(
      eventParts,
      customAreaEnabled,
      customAreaEvent,
      customAreaIconUrl,
      customAppEnabled,
      customApps,
      customAppEvent,
      customAppIconUrl,
    ),
    {},
  );

  const [landingArea, setLandingArea] = useState(
    eventLandingArea || defaultLandingArea(eventPartChecked),
  );

  const handleSetAsLandingArea = type => {
    setLandingArea(type);
    setEventPartChecked({
      ...eventPartChecked,
      [type]: true,
    });
  };

  const handleShowHide = type => {
    const newChecked = !eventPartChecked[type];
    const newEventPartChecked = {
      ...eventPartChecked,
      [type]: newChecked,
    };
    setEventPartChecked(newEventPartChecked);
    if (
      (landingArea === type && !newChecked) ||
      (landingArea === undefined && newChecked)
    ) {
      setLandingArea(defaultLandingArea(newEventPartChecked));
    }
  };

  const createEventPart = ({
    type,
    name,
    value,
    icon,
    iconColor,
    iconBackgroundColor,
  }) => {
    return (
      <EventPart
        key={type}
        type={type}
        name={name}
        value={value}
        icon={icon}
        iconColor={iconColor}
        iconBackgroundColor={iconBackgroundColor}
        checked={eventPartChecked[type]}
        isLandingArea={landingArea === type}
        handleSetAsLandingArea={handleSetAsLandingArea}
        handleShowHide={() => handleShowHide(type)}
        isLite={isLite}
      />
    );
  };

  return (
    <Box mt={5}>
      <Text element="h2" mb={1} pattern="headingFour">
        {t('basic-settings.event-parts.heading')}
      </Text>
      <Text
        pattern="labelThree"
        mt={1}
        mb={2}
        style={{ display: 'block', fontSize: '0.875rem' }}
      >
        {legend}
      </Text>
      <SelectGroup name="event_parts[]" className="eventPartsSelect">
        <Input
          type="text"
          name="event[landing_area]"
          value={landingArea}
          hidden={true}
          readOnly={true}
        />
        <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="1rem 16px">
          {eventParts.map(({ event_part_type: type }) => {
            const part = eventPartsInfo[type];
            return part
              ? createEventPart({
                  type: type,
                  name: 'event_parts[]',
                  value: type,
                  icon: part.icon,
                  iconColor: part.iconColor,
                  iconBackgroundColor: part.iconBackgroundColor,
                })
              : null;
          })}
          {customAreaEnabled &&
            createEventPart({
              type: CUSTOM_AREA,
              name: 'custom-event-part-area',
              value: eventPartChecked[CUSTOM_AREA],
              icon: 'link',
              iconColor: 'grey-700',
              iconBackgroundColor: 'grey-100',
            })}
          {customAppEnabled &&
            createEventPart({
              type: CUSTOM_APP,
              name: 'custom-event-part-app',
              value: eventPartChecked[CUSTOM_APP],
              icon: 'add-component',
              iconColor: 'grey-700',
              iconBackgroundColor: 'grey-100',
            })}
        </Grid>
      </SelectGroup>
      {customAreaEnabled && (
        <EventCustomArea
          customEvent={customAreaEvent}
          iconUrl={customAreaIconUrl}
          showForm={eventPartChecked[CUSTOM_AREA]}
        />
      )}
      {customAppEnabled && (
        <EventCustomApp
          customEvent={customAppEvent}
          customApps={customApps}
          iconUrl={customAppIconUrl}
          showForm={eventPartChecked[CUSTOM_APP]}
        />
      )}
    </Box>
  );
};

EventParts.propTypes = {
  legend: string,
  eventParts: arrayOf(
    shape({
      id: number,
      event_part_type: string.isRequired,
      visible: bool,
    }),
  ).isRequired,
  customAreaEnabled: bool.isRequired,
  customAreaEvent: shape({
    id: number,
    title: string,
    url: string,
    new_window: bool,
  }),
  customAreaIconUrl: string,
  customAppEnabled: bool.isRequired,
  customApps: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      post_configuration: arrayOf(object),
    }),
  ),
  customAppEvent: shape({
    id: number,
    title: string,
    url: string,
  }),
  customAppIconUrl: string,
  eventLandingArea: string,
  isLite: bool,
};

export default EventParts;
