import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import DoorsOpen from './assets/pre-event-banner.png';

const DoorsOpenModal = ({
  modalOpen,
  setModalOpen,
  eventId,
  showEmailLink,
  supportUrl,
}) => {
  const { t } = useLocalization('basic-settings');

  return (
    <Modal
      describedById="doors-open-modal"
      isShowing={modalOpen}
      onClose={() => setModalOpen(false)}
      isDismissible
      label="doors-open"
      withCloseButton
    >
      <Header id="doors-open-modal">
        <Text pattern="title" element="h1">
          {t('pre-event.modal.during')}
          <br />
          {t('pre-event.modal.early-access')}
        </Text>
      </Header>
      <Box my={4} mx={6}>
        <Box as="ul" mb={7}>
          <ListItem>
            <Text>{t('pre-event.modal.features.event-parts')}</Text>
          </ListItem>
          <ListItem>
            <Text>
              {t('pre-event.modal.features.polls-chat-qa')}
              <br />
            </Text>
            <Text
              element="a"
              href={Routes.organisersEventVenueControlsPath(eventId)}
            >
              {t('pre-event.modal.features.polls-link')}
            </Text>
          </ListItem>
          <ListItem>
            <Text mb={2}>{t('pre-event.modal.features.email')}</Text>
            {showEmailLink && (
              <Text
                element="a"
                href={Routes.organisersEventCommunicationsPath(eventId)}
              >
                {t('pre-event.modal.features.email-link')}
              </Text>
            )}
          </ListItem>
        </Box>
        <Button
          isOutlined
          href={supportUrl}
          target="_blank"
          pattern="primary"
          size="medium"
        >
          {t('pre-event.modal.features.cta')}
        </Button>
      </Box>
    </Modal>
  );
};

export default withLocalizationProvider(DoorsOpenModal);

DoorsOpenModal.propTypes = {
  setModalOpen: func,
  modalOpen: bool,
  showEmailLink: bool,
  eventId: number,
  supportUrl: string,
};

const Header = styled.div`
  padding: 1rem;
  background-color: var(--color-blue-100);
  padding: 2.375rem 2.125rem 2rem 2.125rem;
  background-image: url(${DoorsOpen});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;
