import Alerts from '@features/alerts/alerts';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import React from 'react';

import { eventHasSimplifiedNav } from './constants';
import { Styles } from './nav-styles';
import Footer from './partials/footer';
import Header from './partials/header';
import NavTree from './partials/nav-tree';

const NavComponent = ({
  navigationView,
  event,
  isLoading,
  userImage,
  userName,
  onEventDelete,
  onPublish,
  onEventDuplicate,
  creationEnableGlobalTags,
  newRegistrationsAttendeesEnabled,
  newRegistrationsUrl,
  newRegistrationsDashboardEnabled,
  supportUrl,
  hopinCanvasEnabled,
  ticketGroupsDeprecated,
  venueType,
  onsiteUpgradeFlowEnabled,
  isAppAreaVisible,
  surveyBuilderEnabled,
  reorderEventDashboardNavTabs,
  canPublishEvent,
  showEngagementScore,
  reactPagesVariation,
}) => {
  return (
    <Styles.Wrapper>
      {!isLoading && (
        <>
          <Alerts />
          <Header
            event={event}
            onEventDelete={onEventDelete}
            onPublish={onPublish}
            onEventDuplicate={onEventDuplicate}
            supportUrl={supportUrl}
            canPublishEvent={canPublishEvent}
          />
          <NavTree
            event={event}
            creationEnableGlobalTags={creationEnableGlobalTags}
            newRegistrationsAttendeesEnabled={newRegistrationsAttendeesEnabled}
            newRegistrationsUrl={newRegistrationsUrl}
            newRegistrationsDashboardEnabled={newRegistrationsDashboardEnabled}
            hopinCanvasEnabled={hopinCanvasEnabled}
            ticketGroupsDeprecated={ticketGroupsDeprecated}
            venueType={venueType}
            onsiteUpgradeFlowEnabled={onsiteUpgradeFlowEnabled}
            isAppAreaVisible={isAppAreaVisible}
            surveyBuilderEnabled={surveyBuilderEnabled}
            reorderEventDashboardNavTabs={reorderEventDashboardNavTabs}
            showEngagementScore={showEngagementScore}
            reactPagesVariation={reactPagesVariation}
          />
          <Footer
            navigationView={navigationView}
            userImage={userImage}
            userName={userName}
            advancedToggleVisible={eventHasSimplifiedNav(
              event.eventWizardTemplate,
            )}
          />
        </>
      )}
    </Styles.Wrapper>
  );
};

NavComponent.propTypes = {
  navigationView: string,
  creationEnableGlobalTags: bool,
  event: shape({
    eventId: string.isRequired,
    canDelete: bool.isRequired,
    slug: string.isRequired,
    hasTickets: bool.isRequired,
    isDraft: bool.isRequired,
    maxPrice: number.isRequired,
    organization: object.isRequired,
  }).isRequired,
  isLoading: bool,
  userImage: string,
  userName: string.isRequired,
  onEventDelete: func.isRequired,
  onPublish: func.isRequired,
  onEventDuplicate: func.isRequired,
  newRegistrationsAttendeesEnabled: bool,
  newRegistrationsUrl: string.isRequired,
  newRegistrationsDashboardEnabled: bool,
  supportUrl: string.isRequired,
  hopinCanvasEnabled: bool,
  ticketGroupsDeprecated: bool,
  venueType: string.isRequired,
  onsiteUpgradeFlowEnabled: bool,
  isAppAreaVisible: bool,
  surveyBuilderEnabled: bool,
  reorderEventDashboardNavTabs: bool,
  canPublishEvent: bool,
  showEngagementScore: bool,
  reactPagesVariation: arrayOf(string),
};

export default NavComponent;
