import { CookieConsent as SharedCookieConsent } from '@hopin-team/cookie-consent';
import { bool } from 'prop-types';
import React from 'react';

const CookieConsent = ({ enabled }) => (
  <SharedCookieConsent disabled={!enabled} />
);

CookieConsent.propTypes = {
  enabled: bool,
};

export default CookieConsent;
