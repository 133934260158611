import '@hopin-team/ckeditor5-build-hopin/dist/styles.css';
import './languages';
import './styles.css';

import CKEditor from '@ckeditor/ckeditor5-react';
import {
  LocalizationContext,
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { ClassicEditor } from '@hopin-team/ckeditor5-build-hopin';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { getGroupedLiquidTagConfig } from './grouped-liquid-tags-config';
import {
  BRANDING_COLORS,
  ClassicEditorResizableContainer,
  DEFAULT_COLORS,
} from './styles';

ClassicEditor.IFRAMELY_API_KEY_HASH = process.env.IFRAMELY_API_KEY_HASH;

const CKEditor5Classic = forwardRef(
  (
    { name, initialValue, uploadS3PolicyUrl, maxFileSize, onChange, height },
    ref,
  ) => {
    const [data, setData] = useState(initialValue);
    const editorRef = useRef();
    const { language } = useLocalization();
    const { t } = useContext(LocalizationContext);
    const groupedLiquidTagConfig = getGroupedLiquidTagConfig({ t });

    useEffect(() => {
      if (!editorRef.current) return;
    });

    const config = {
      fontColor: {
        columns: 5,
        colors: [...BRANDING_COLORS, ...DEFAULT_COLORS].map(color => ({
          color,
        })),
      },
      language,
      toolbar: {
        items: [
          'undo',
          'redo',
          '|',
          'sourceEditing',
          '|',
          'alignment',
          'bulletedList',
          'numberedList',
          '|',
          'insertImage',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          '|',
          'liquid-tag',
          '-',
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'fontColor',
          'link',
          'specialCharacters',
          '|',
        ],
        shouldNotGroupWhenFull: true,
      },
      insert: {
        integrations: ['upload', 'url'],
      },
      image: {
        toolbar: [
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
          '|',
          'imageResize',
          '|',
          'toggleImageCaption',
          'imageTextAlternative',
          '|',
          'linkImage',
        ],
      },
      ui: {
        viewportOffset: {
          top: 100,
        },
      },
      removePlugins: ['Title'],
      placeholder: '',
      liquidTagConfig: groupedLiquidTagConfig,
    };

    if (uploadS3PolicyUrl && maxFileSize) {
      config.s3Upload = {
        policyUrl: uploadS3PolicyUrl,
        maxFileSize: maxFileSize,
      };
    }

    return (
      <div>
        <textarea
          value={data}
          name={name}
          style={{ display: 'none' }}
          readOnly
        ></textarea>

        <div className="document-editor">
          <div className="document-editor__toolbar"></div>
          <div className="document-editor__editable-container">
            <ClassicEditorResizableContainer
              height={height}
              data-testid="ckeditor-classic"
            >
              <CKEditor
                config={config}
                onInit={editor => {
                  editorRef.current = editor;
                  window.editor = editor;
                  const widgetTypeAroundPlugin = editor.plugins.get(
                    'WidgetTypeAround',
                  );
                  widgetTypeAroundPlugin.forceDisabled('MyApplication');
                }}
                ref={ref}
                editor={ClassicEditor}
                data={initialValue}
                onChange={(event, editor) => {
                  setData(editor.getData());
                  if (onChange) onChange(event, editor);
                }}
              />
            </ClassicEditorResizableContainer>
          </div>
        </div>
      </div>
    );
  },
);

CKEditor5Classic.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  uploadS3PolicyUrl: PropTypes.string,
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  height: PropTypes.number,
};

export default withLocalizationProvider(CKEditor5Classic);
