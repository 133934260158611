import hopinApi from '@api/hopin';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Spinner } from '@hopin-team/ui-spinner';
import { bool, object, string } from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';

import * as Styled from './event-tickets-component.styles';

const renderOverlay = props => {
  return <Styled.TicketsModalOverlay {...props} />;
};

function dismissOneTrustDialog() {
  const ot = window?.OneTrust;

  if (ot && !ot.IsAlertBoxClosed()) {
    ot.Close();
    return;
  }
}

export const EventTickets = ({
  eventSlug,
  buttonLabel,
  buttonProps,
  registrationWidgetUrl,
  registrationFormLayoutV2,
}) => {
  const { t } = useContext(LocalizationContext);
  const [isModalDisplayed, setModalDisplayed] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [reopenCookieDialog, setReopenCookieDialog] = useState();

  const handleCookieDialog = useCallback(
    (/** @type {{ openingModal: boolean}}*/ { openingModal }) => {
      try {
        const cm = window?.Osano?.cm;

        if (cm) {
          if (openingModal && cm.dialogOpen) {
            setReopenCookieDialog(true);
            cm.hideDialog();
            return;
          }

          if (reopenCookieDialog) {
            cm.showDialog();
            setReopenCookieDialog(false);
          }

          return;
        }

        if (openingModal) dismissOneTrustDialog();
      } catch {
        // Swallow error likely that osano is not available on the window.
      }
    },
    [reopenCookieDialog],
  );

  const handleModalClose = useCallback(() => {
    document.body.classList.remove('registration-modal-open');
    handleCookieDialog({ openingModal: false });
    setModalDisplayed(false);
  }, [handleCookieDialog]);

  const handleModalOpen = useCallback(async () => {
    try {
      setLoading(true);
      document.body.classList.add('registration-modal-open');
      const { token } = await hopinApi.getUserToken();

      let response;

      response = await hopinApi.getSimpleFlow(
        token,
        eventSlug,
        window.location.search,
      );

      if (response.is_simple_flow) {
        window.location.assign(response.reception_url);
      } else {
        handleCookieDialog({ openingModal: true });
        setModalDisplayed(true);
      }
    } catch (e) {
      handleCookieDialog({ openingModal: true });
      setModalDisplayed(true);
    } finally {
      setLoading(false);
    }
  }, [eventSlug, handleCookieDialog]);

  return (
    <>
      {isLoading ? (
        <Styled.SpinnerContainer>
          <Spinner
            mt={buttonProps?.mt}
            pattern="jumbo"
            isShowing={true}
            data-testid="event-tickets-spinner"
          />
        </Styled.SpinnerContainer>
      ) : (
        <Button onClick={handleModalOpen} {...buttonProps}>
          {buttonLabel}
        </Button>
      )}
      <Styled.TicketsModal
        renderOverlay={renderOverlay}
        describedById="ticket-modal-content"
        isShowing={isModalDisplayed}
        isDismissible={false}
        onClose={handleModalClose}
        size="huge"
        label={t('registration-v1.event-registration')}
        closeLabel={t('registration-v1.close')}
        withCloseButton
        className={
          registrationFormLayoutV2 ? 'registration-form-layout-v2' : ''
        }
      >
        <iframe
          id="ticket-modal-content"
          title={t('registration-v1.registrations')}
          src={registrationWidgetUrl}
          className="widget-iframe"
          // We add tabindex 0 here to allow tabbing inside of the iframe from within the focus trap.
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        />
      </Styled.TicketsModal>
    </>
  );
};

EventTickets.propTypes = {
  eventSlug: string.isRequired,
  buttonLabel: string.isRequired,
  buttonProps: object,
  registrationWidgetUrl: string.isRequired,
  registrationFormLayoutV2: bool,
};
